<template>
  <div class="time_counter"></div>
</template>

<script>
import { sleep } from "@/utils/utils.js";
export default {
  data() {
    return {
      totalSeconds: 0, //计时秒数
      isCounting: false, //是否正在计时
    };
  },
  methods: {
    startCount() {
      this.totalSeconds = 0;
      this.isCounting = true;
      this.countCore();
    },
    stopCount() {
      this.isCounting = false;
      return this.totalSeconds;
    },
    async countCore() {
      if (!this.isCounting) {
        return;
      }
      await sleep(500);
      this.totalSeconds += 0.5;
      this.countCore();
    },
  },
  mounted() {},
};
</script>
